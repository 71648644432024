import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Contactbar() {

    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 110);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <><div
            className={`contact shadow row d-flex ${isScrolled ? "scrolled" : "d-none"}`}>
            <div className="container">
                <div className="contactbar">
                    <div class="footer_menyu_sss">
                        <div className="row">
                            <div class="link_section col-3">
                                <a href="https://wa.me/7200446380" target="_blank">
                                    <i class="fab fa-whatsapp blob"></i>
                                </a>
                                <span className="linkcontact">+91 72004 46380</span>
                            </div>
                            <div class="link_section col-3">
                                <a
                                    href="https://join.skype.com/invite/w0Rjy2HWIpzT"
                                    target="_blank"
                                >
                                    <i class="fab fa-skype blob"></i>
                                </a>
                                <span className="linkcontact">live:.cid.141e5075ec6b6e44
</span>
                            </div>
                            <div class="link_section col-3">
                                <a href="tel:+91 72004 46380" target="_blank">
                                    <i class="fas fa-phone blob"></i>
                                </a>
                                <span  className="linkcontact">+91 72004 46380</span>
                            </div>
                            <div class="link_section col-3">
                                <a href="mailto:squirrelbytestechnologies@gmail.com" target="_blank">
                                    <i class="fas fa-envelope blob"></i>
                                </a>
                                <span  className="linkcontact">squirrelbytestechnologies</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
