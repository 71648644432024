import React from 'react'
import Header from '../Components/Header'
import { useNavigate } from "react-router-dom";


export default function Page_Not_found() {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <Header />
        <section className="pagenotfound mt-5">
          <div className='container'>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <img
                  className="my-2 d-block mx-auto"
                  width="40%"
                  src={require("../Images/soon.png")}
                  alt="404"
                />
              </div>
             
            </div>
            <h2 className="text-center">We are under Maintenance</h2><br/>
            <h4 className="text-center">Will be back soon !</h4><br/>


              <button
                class="btn d-block mx-auto"
                onClick={() => navigate("/")}
                type="submit"
              >
                Goto Homepage
              </button>
          </div>
        </section>
      </div>
    </>
  )
}
