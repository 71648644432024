import React from 'react'
import Header from '../Components/Header'
import { useNavigate } from "react-router-dom";


export default function Page_Not_found() {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <Header />
        <section className="pagenotfound">
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <img
                  className="my-2 d-block mx-auto"
                  width="60%"
                  src={require("../Images/404.png")}
                  alt="404"
                />
              </div>
             
            </div>
            <h2 className="text-center">Sorry, Page not found !</h2><br/>
              <button
                class="btn d-block mx-auto"
                onClick={() => navigate("/")}
                type="submit"
              >
                Goto Homepage
              </button>
          </div>
        </section>
      </div>
    </>
  )
}
