import React from "react";
import "../Pages/style.css";

export default function Footer() {
  return (
    <>
      <section className="copyrights">
        <div className="footer1">
          <div className="container-fluid">
            <div className="row">
              <div class="col-lg-4">
                <img
                  className="my-2"
                  width="75%"
                  src={require("../Images/logolight.png")}
                  alt="upload"
                />
              </div>
              <div class="col-lg-4"></div>

              <div class="col-lg-4 footer1-contact">
                <h4 className="footer1-h4">CONTACT US</h4>
                <p className="footer1-p">
                  <i class="fas fa-envelope" id="mail"></i>
                  squirrelbytes@gmail.com
                </p>
              </div>
            </div>
          </div>

          <div>
            <h5>© Copyright 2023 Squirrel Bytes | All Rights Reserved </h5>
          </div>
        </div>
      </section>
    </>
  );
}
