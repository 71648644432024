import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "../Pages/style.css";
import newLogo from "../Images/logos.png";

export default function Header() {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Change the value (e.g., 100) to the desired scroll position where you want to update the header
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`navbar navbar-header navbar-expand-lg ${isScrolled ? "scrolled" : ""
          }`}
      >
        <div className="container-fluid">
          <NavLink to="/" className="navbar-brand un-hide logo">
            <img src={isScrolled ? newLogo : newLogo} alt="CoolBrand" />
          </NavLink>
          <button
            class="navbar-toggler navbar-light"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <div className="navbar-nav ml-auto">

              <div class="dropdown nav-drop mt-2">
                <button
                  class="navlink dropdown-toggle mt-2 mr-3"
                  type="button"
                  data-toggle="dropdown"
                  data-hover="dropdown"
                >
                  Services <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <NavLink to="/soon" className="navlink my-1 d-flex">
                      Web Development
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soon" className="navlink my-1 d-flex">
                      Mobile App Development
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soon" className="navlink my-1 d-flex">
                      Blockchain Development
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soon" className="navlink my-1 d-flex">
                      AI / ML Development
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/student_project" className="navlink my-1 d-flex">
                      Student Projects
                    </NavLink>
                  </li>
                </ul>
              </div>

              <NavLink
                to="/soon"
                className="navlink mt-3 mr-3"
              >
                Careers
              </NavLink>

              <button
                class="btn nav-btn my-2 my-sm-10"
                onClick={() => navigate("/register")}
                type="submit"
              >
                Contact
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
