import React, { useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Contactbar from "../Components/Contactbar";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import { Form, FormGroup, Label, Input, Col, Button } from "reactstrap";



export default function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <Contactbar />
      <div className="home_head">
        <section className="hero">
          <div className="hero-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="hero-content"
                    data-aos="fade-down"
                    data-aos-delay="700"
                  >
                    <h1>Turn Dreams</h1>
                    <h3
                      className="para"
                      data-aos="fade-right"
                      data-aos-delay="700"
                    >
                      Into Thriving Businesses With Our Skills
                    </h3>
                    <p data-aos="fade-up" data-aos-delay="600">
                      Our Mission Is To Meticulously Develop And Deliver
                      Efficient, User-Friendly Software & Web Development
                      Solutions Tailored To Your Needs
                    </p>

                    <button
                      class="btn my-2 d-block mx-auto"
                      data-aos="zoom-out-right"
                      data-aos-delay="750"
                      onClick={() => navigate("/login")}
                      type="submit"
                    >
                      Explore
                    </button>
                  </div>
                </div>
                <div>
                  <img
                    className="d-block mx-auto mt-5"
                    width="38%"
                    src={require("../Images/homebanner.png")}
                    alt="upload"
                  />
                </div>
                {/* <div className="col-lg-6" data-aos="fade-left" data-aos-offset="600" data-aos-easing="ease-in-sine" > */}
              </div>
            </div>
          </div>
        </section>


        <section className="aboutsec">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2 className="my-3">About Squirrel Bytes</h2>
                <p className="mt-2">Greetings from Squirrel Bytes, your one-stop shop for all-inclusive tech solutions. We combine experience and creativity in our web development, mobile development, software development, AI/ML development, and blockchain development specialties. Our innovative team of tech enthusiasts is dedicated to creating solutions that are specifically customized to meet your demands. We turn concepts into digital realities using a customer-centric methodology, making sure every project meets or surpasses expectations. Come along on a trip where quality and creativity collide, and together, let's construct the future.</p>
              </div>
              <div className="col-lg-5">
                <img
                  className="d-block mx-auto mt-4"
                  width="100%"
                  src={require("../Images/home.png")}
                  alt="upload"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whychoosen">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <img
                  className="d-block mx-auto mt-4"
                  width="80%"
                  src={require("../Images/whychoosen.png")}
                  alt="upload"
                />
              </div>
              <div className="col-lg-7 mt-5">
                <h2 className="my-3">Why Choose <span>Squirrel Bytes</span> ? </h2>
                <p className="mt-2">Squirrel Bytes, a leading web development company, stands as the architect of development in the digital world. Our team combines technical prowess with a passion for innovation, ensuring that your project stands out in a crowded space. We specialize in crafting efficient, user-friendly software and web development solutions. Join us, and let's collaboratively shape the future of service, one block at a time.</p>
              </div>
            </div>
          </div>
        </section>


        <section className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="my-3">Our <span>Innovative</span> Services </h2>
                <h5 className="mt-2">Innovative Solutions Tailored for You</h5>
              </div>

            </div>

            <div className="row mt-3">
              <div className="col-lg-4 mb-4">
                <div class="servicecard">
                  <div class="content">
                    <div class="back">
                      <div class="back-content">
                        <img
                          className="d-block mx-auto"
                          width="100%"
                          src={require("../Images/webdev.png")}
                          alt="upload"
                        />

                        <strong className="text-center">
                          Mobile App <br />
                          Development
                        </strong>
                      </div>
                    </div>
                    <div class="front">
                      <div class="front-content">
                        <div class="description">
                          <div class="title mt-3">
                            <strong className="text-center">
                              Immerse your brand in the mobile realm with our
                              cutting-edge mobile app development services. From
                              centralized applications to decentralized solutions
                              on blockchain, we craft apps that resonate with your
                              audience and drive engagement.
                            </strong>
                          </div>
                        </div>
                        <small class="badge mt-3">App Development</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div class="servicecard">
                  <div class="content">
                    <div class="back">
                      <div class="back-content">
                        <img
                          className="d-block mx-auto"
                          width="80%"
                          src={require("../Images/mobdev.webp")}
                          alt="upload"
                        />

                        <strong className="text-center pb-2 ">
                          Web <br />
                          Development
                        </strong>
                      </div>
                    </div>
                    <div class="front">
                      <div class="front-content">
                        <div class="description">
                          <div class="title mt-3">
                            <strong className="text-center">
                              Elevate your online presence with our responsive and
                              scalable web development solutions. Whether you're
                              looking for centralized websites or exploring the
                              decentralized web, we create platforms that deliver
                              seamless user experiences across devices.
                            </strong>
                          </div>
                        </div>
                        <small class="badge mt-3">Web Development</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div class="servicecard">
                  <div class="content">
                    <div class="back">
                      <div class="back-content">
                        <img
                          className="d-block mx-auto"
                          width="90%"
                          src={require("../Images/block.webp")}
                          alt="upload"
                        />

                        <strong className="text-center">
                          Blockchain <br />
                          Development
                        </strong>
                      </div>
                    </div>
                    <div class="front">
                      <div class="front-content">
                        <div class="description">
                          <div class="title mt-3">
                            <strong className="text-center">
                              Dive into the world of blockchain with our cutting-edge development services. From centralized apps to decentralized solutions, we create secure and efficient solutions that resonate with your audience.
                            </strong>
                          </div>
                        </div>
                        <small class="badge mt-3">Blockchain Development</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-4">
                <div class="servicecard">
                  <div class="content">
                    <div class="back">
                      <div class="back-content">
                        <img
                          className="d-block mx-auto"
                          width="90%"
                          src={require("../Images/robo.webp")}
                          alt="upload"
                        />

                        <strong className="text-center">
                          AI / ML <br />
                          Development
                        </strong>
                      </div>
                    </div>
                    <div class="front">
                      <div class="front-content">
                        <div class="description">
                          <div class="title mt-3">
                            <strong className="text-center">
                              Explore AI/ML possibilities with our advanced development services. From smart applications to machine learning algorithms, we drive innovation to enhance automation, decision-making, and user experiences.
                            </strong>
                          </div>
                        </div>
                        <small class="badge mt-3">AI / ML Development</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-4">
                <div class="servicecard">
                  <div class="content">
                    <div class="back">
                      <div class="back-content">
                        <img
                          className="d-block mx-auto"
                          width="90%"
                          src={require("../Images/3d.png")}
                          alt="upload"
                        />

                        <strong className="text-center">
                          Graphics <br />
                          Design
                        </strong>
                      </div>
                    </div>
                    <div class="front">
                      <div class="front-content">
                        <div class="description">
                          <div class="title mt-3">
                            <strong className="text-center">
                              Transform your brand visually with our creative graphic design services. From logos to marketing materials, our designs captivate your audience and convey your brand's essence effectively.
                            </strong>
                          </div>
                        </div>
                        <small class="badge mt-3">Graphics Design</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-4">
                <div class="servicecard">
                  <div class="content">
                    <div class="back">
                      <div class="back-content">
                        <img
                          className="d-block mx-auto"
                          width="90%"
                          src={require("../Images/product.webp")}
                          alt="upload"
                        />

                        <strong className="text-center">
                          Product
                          <br />
                          Development
                        </strong>
                      </div>
                    </div>
                    <div class="front">
                      <div class="front-content">
                        <div class="description">
                          <div class="title mt-3">
                            <strong className="text-center">
                              Embark on a journey of product innovation with
                              Squirrel Bytes. Our team specializes in creating
                              cutting-edge products for both centralized and
                              decentralized ecosystems, addressing real-world
                              challenges and enriching the lives of users.
                            </strong>
                          </div>
                        </div>
                        <small class="badge mt-3">Product Development</small>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="inovative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 p-5 datas">
                <h4 className="xdc-section-h2">
                  Project <br /> Envisioning
                </h4>
                <p>
                  Define and visualize your project's scope, goals, and requirements. Lay the foundation for a
                  clear and comprehensive road-map.
                </p>
              </div>
              <div className="col-lg-4 p-5 shadow datas">
                <h4>
                  Strategizing & <br /> Implementation
                </h4>
                <p>Develop a robust strategy based on the envisioned project. Implement well-defined plans,
                  allocating resources effectively for seamless execution</p>
              </div>
              <div className="col-lg-4 p-5 datas">
                <h4>
                  Deploy & <br /> Sustain
                </h4>
                <p>Roll out your project, ensuring a smooth deployment process. Sustain its success by monitoring,
                  maintaining, and adapting to evolving needs.</p>
              </div>
            </div>
          </div>
        </section>


        <section className="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="my-3"><span>Features</span> </h2>
                <h5 className="mt-2">Unlock the Power of Squirrel Bytes</h5>
              </div>
            </div>

            <div className="row mt-4 justify-content-between">
              <div className="col-lg-4 my-2">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/tech.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Advanced Technology</h4>
                  <p className="text-center">
                    Experience cutting-edge solutions crafted with the latest
                    technologies, ensuring your digital presence is always ahead
                    of the curve.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/integrate.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Seamless Integration</h4>
                  <p className="text-center">
                    Our products seamlessly integrate with your existing systems,
                    providing a smooth and efficient experience for your business
                    processes.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/user.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">User-Friendly Design</h4>
                  <p className="text-center">
                    Enjoy intuitive and user-friendly designs that enhance
                    usability and create a positive experience for your audience.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/scalable.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Scalability</h4>
                  <p className="text-center">
                    Grow your business effortlessly with scalable solutions that
                    adapt to your evolving needs, providing flexibility and
                    future-proofing your investments.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/security.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Data Security</h4>
                  <p className="text-center">
                    Rest easy knowing that your sensitive data is secure with our
                    robust security measures, ensuring the confidentiality and
                    integrity of your information.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/support.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">24/7 Support</h4>
                  <p className="text-center">
                    Our dedicated support team is available around the clock to
                    assist you, ensuring a seamless experience and quick
                    resolution of any issues.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/customize.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Customization Options</h4>
                  <p className="text-center">
                    Tailor our products to fit your unique requirements with a
                    range of customization options, ensuring a personalized
                    solution for your business.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/cost.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Cost-Effective Solutions</h4>
                  <p className="text-center">
                    Maximize your ROI with cost-effective solutions that deliver
                    exceptional value without compromising on quality and
                    performance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-2 ">
                <div class="Bcard">
                  <img
                    className="d-block mx-auto"
                    width="25%"
                    src={require("../Images/collab.png")}
                    alt="upload"
                  />

                  <h4 className="text-center">Collaborative Innovation</h4>
                  <p className="text-center">
                    Join us in a collaborative journey of innovation, where your
                    ideas and feedback contribute to the continuous improvement of
                    our products and services.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </section>


        <section className="techs">
          <div className="container-fluid">
            <h2
              className="tool-section-h2 mt-3"
              data-aos="fade-down"
              data-aos-delay="400"
            >
              Our Tech Stack
            </h2>
            <h5
              className="text-center w-80 p primary"
              data-aos="fade-right"
              data-aos-delay="450"
            > we are concerned about the security of our customers. That's why we always keep updating and use best technologies in our products.
            </h5>
            <section className="techstack">
              <div className="">
                <div className="row tech">
                  <div className="col-lg-12 techstacks">
                    <div className="slider">
                      <div className="slide-track">
                        <div className="slide">
                          <img
                            src={require("../Images/html.png")}
                            className="bitcoin"
                            alt="html"
                          />
                          <p>Html</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/css.png")}
                            className="bitcoin"
                            alt="Css"
                          />
                          <p>Css</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/js.png")}
                            className="bitcoin"
                            alt="Java Script"
                          />
                          <p>Java Script</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/bootstrap.png")}
                            className="bitcoin"
                            alt="Bootstrap"
                          />
                          <p>Bootstrap</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/next.png")}
                            className="bitcoin"
                            alt="Next JS"
                          />
                          <p>Next Js</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/vue.png")}
                            className="bitcoin"
                            alt="Vue JS"
                          />
                          <p>Vue JS</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/react.png")}
                            className="bitcoin"
                            alt="React"
                          />
                          <p>React</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/angular.png")}
                            className="bitcoin"
                            alt=" Angular"
                          />
                          <p>Angular</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/illustrator.png")}
                            className="bitcoin"
                            alt="Illustrator "
                          />
                          <p>Illustrator</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/photoshop.png")}
                            className="bitcoin"
                            alt="Photoshop"
                          />
                          <p>Photoshop</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/python.png")}
                            className="bitcoin"
                            alt="Python"
                          />
                          <p>Python</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/php.png")}
                            className="bitcoin"
                            alt="PHP"
                          />
                          <p>PHP</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/node.png")}
                            className="bitcoin"
                            alt="Node JS"
                          />
                          <p>Node JS</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/laravel.png")}
                            className="bitcoin"
                            alt="Lavarel"
                          />
                          <p>Lavarel</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/mongo.png")}
                            className="bitcoin"
                            alt="MongoDB"
                          />
                          <p>MongoDB</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/postgreaql.png")}
                            className="bitcoin"
                            alt="PostgreSQL"
                          />
                          <p>PostgreSQL</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/mysql.png")}
                            className="bitcoin"
                            alt="MYSQL"
                          />
                          <p>MYSQL</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/elastic.png")}
                            className="bitcoin"
                            alt="Elastic"
                          />
                          <p>Elastic Search</p>
                        </div>
                        <div className="slide">
                          <img
                            src={require("../Images/flutter.png")}
                            className="bitcoin"
                            alt="Flutter"
                          />
                          <p>Flutter</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


          </div>
        </section>

        <section className="faq">
          <div id="main">
            <div class="container">
              <div className="faq-content">
                <p className="p-mobile">Have a Question ?</p>
                <h3 className="h2-mobile my-3">
                  Frequently Asked Questions
                </h3>
              </div>
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header" id="faqhead1">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      What is Web3 Storage, and how does it work?
                    </a>
                  </div>

                  <div
                    id="faq1"
                    class="collapse"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Web3 Storage is a decentralized storage solution that
                      leverages blockchain technology to store data across a
                      network of nodes. It ensures data security and accessibility
                      by eliminating single points of failure. When you upload a
                      document, it gets encrypted, divided into pieces, and stored
                      across multiple nodes, making it highly resilient and immune
                      to censorship.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead2">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                    >
                      Is my data safe and private on Web3 Storage?
                    </a>
                  </div>

                  <div
                    id="faq2"
                    class="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Absolutely! With Web3 Storage, your data is protected
                      through robust encryption and cryptographic mechanisms. Only
                      you, as the owner, have the private keys to access and
                      control your documents. Since the storage network is
                      decentralized, there's no central authority to access your
                      data without your permission, ensuring unmatched privacy and
                      security.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      How does Web3 Storage compare to traditional cloud storage?
                    </a>
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Unlike traditional cloud storage, which relies on
                      centralized servers, Web3 Storage operates on a distributed
                      network, offering superior data resilience and protection
                      against potential data breaches. Additionally, Web3 Storage
                      puts data ownership back into the hands of users, allowing
                      them to manage access and prevent data misuse. It also
                      ensures censorship resistance, making it ideal for sensitive
                      documents that require the utmost privacy and security.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contactus " id="contact">
          <div className="container">
            <h2
              className="tool-section-h2 mt-3"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              Get In Touch
            </h2>
            <div className="row m-0 justify-content-center">
              <div className="col-lg-8">
                <Form>
                  <FormGroup>
                    <Label
                      for="name"
                      sm={2}
                      data-aos="fade-down"
                      data-aos-delay="500"
                    >
                      Name
                    </Label>
                    <br />
                    <Col sm={12}>
                      <Input
                        className="p-4"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your Name"

                        required
                        data-aos="fade-right"
                        data-aos-delay="500"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="email"
                      sm={2}
                      data-aos="fade-down"
                      data-aos-delay="500"
                    >
                      Email
                    </Label>
                    <Col sm={12}>
                      <Input
                        className="p-4"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email"

                        required
                        data-aos="fade-right"
                        data-aos-delay="500"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="phone"
                      sm={2}
                      data-aos="fade-down"
                      data-aos-delay="500"
                    >
                      Phone
                    </Label>
                    <Col sm={12}>
                      <Input
                        className="p-4"
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder="Your Phone"

                        required
                        data-aos="fade-right"
                        data-aos-delay="500"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="country"
                      sm={2}
                      data-aos="fade-down"
                      data-aos-delay="500"
                    >
                      Country
                    </Label>
                    <Col sm={12}>
                      {/* Use a country dropdown component or library here */}
                      {/* For simplicity, you can use a regular Input for now */}
                      <Input
                        className="p-4"
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Your Country"

                        required
                        data-aos="fade-right"
                        data-aos-delay="500"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="message"
                      sm={2}
                      data-aos="fade-down"
                      data-aos-delay="500"
                    >
                      Message
                    </Label>
                    <Col sm={12}>
                      <Input
                        className="p-4"
                        type="textarea"
                        name="message"
                        id="message"
                        placeholder="Your Message"
                        required
                        data-aos="fade-right"
                        data-aos-delay="500"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup data-aos="fade-down" data-aos-delay="500">
                    <Button
                      color="primary"
                      className="mx-auto d-block"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </section>

      </div>
      <Footer />
    </>
  );
}
