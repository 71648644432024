import React from 'react'
import './Pages/style.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Pages/Home'
import PageNotfound from './Pages/Page_Not_found'
import Comingsoon from './Pages/Comingsoon'
import Webdev from './Pages/webdev'
import Mobiledev from './Pages/mobile-dev'
import Blockchain from './Pages/blockchain'
import ai from './Pages/ai-dev'
import Studentproject from './Pages/student-project'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function App() {


  return (
    <div>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={< Home/>} />
      <Route path="*" element={< PageNotfound/>} />
      <Route path="/soon" element={< Comingsoon/>} />
      <Route path="/web_development" element={< Webdev/>} />
      <Route path="/app_development" element={< Mobiledev/>} />
      <Route path="/blockchain_development" element={< Blockchain/>} />
      <Route path="/ai_development" element={< ai/>} />
      <Route path="/student_project" element={< Studentproject/>} />

      </Routes>
      <ToastContainer />
      </BrowserRouter>
    </div>
  )
}
